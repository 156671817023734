// Angular material theme
@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.all-legacy-component-themes(mat.define-light-theme((
	color: (
		primary: mat.define-palette(mat.$red-palette, 500),
		accent: mat.define-palette(mat.$grey-palette),
	)
)));
/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~material-design-icons/iconfont/material-icons.css';
@import './app/styles/color_palette.scss';
html, body { height: 100%; }
body { margin: 0; }

* {
	font-family: Montserrat, sans-serif !important;
	box-sizing: border-box;
}

.material-icons{
    font-family: 'Material Icons' !important;
}

h1 {
	width: 100%;
	font-weight: 500;
	font-size: 36px;
	text-align: center;
	margin: 50px;
	color: #ee3041;
}

h3 {
	font-size: 18px;
    font-weight: 500;
    line-height: 1.1;
    color: #1f1f1f;
}

a {
	text-decoration: none;
	cursor: pointer;
    color: #337ab7;
}

a:hover {
	text-decoration: underline;
	cursor: pointer;
}

content-container > {
	width: 90%;
}

input {
	padding: 0.75rem;
}

.mat-card-header {
	min-height: 56px;
}

.mat-header-cell {
	font-weight: bold;
}

mat-label {
	font-weight: 700;
}

// For the audio player
.controls {
	display: flex !important;
	flex-direction: column !important;

	.btn-group {
		padding-top: 1rem;

		.btn {
			padding: .5rem 1rem;
			margin: 0 .25rem;
			background-color: white;
			border: solid color_palette(secondary, dark) 1px;

			&:hover {
				cursor: pointer;
				background-color: color_palette(secondary, light);
			}
		}	

		// Darker because it's pressed
		.active {
			background-color: color_palette(secondary);
		}
	}

	.track-header {
		height: auto !important;
		padding: .25rem 0.5rem !important;
		background-color: color_palette(primary) !important;
		color: color_palette(primary, text) !important;
		font-size: 1rem !important;
		justify-content: center !important;
	}


	// TODO:2 How do we want to do this with the volume?
	.volume:after {
		visibility: hidden !important;
	}

	.volume:before {
		visibility: hidden !important;
	}
}
